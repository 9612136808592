import {IRouteInfo, ROUTE_NAMES} from "app/services/route/route.types";

const NS = 'RouteInfo';

export const SETUP_INFO: IRouteInfo = {
  name: ROUTE_NAMES.SETUP,
  analyticsUrl: 'setup'
}

export const SITTING_INFO: IRouteInfo = {
  name: ROUTE_NAMES.SITTING,
  analyticsTitle: 'Booking Details',
  analyticsUrl: 'sitting'
}

export const VENUES_INFO: IRouteInfo = {
  name: ROUTE_NAMES.VENUES,
  analyticsTitle: 'Select Venue',
  analyticsUrl: 'venues'
}

export const CUSTOMER_DETAILS_INFO: IRouteInfo = {
  name: ROUTE_NAMES.CUSTOMER_DETAILS,
  analyticsTitle: 'Customer Details',
  analyticsUrl: 'customerDetails'
}

export const SUMMARY_INFO: IRouteInfo = {
  name: ROUTE_NAMES.SUMMARY,
  analyticsTitle: 'Booking Summary',
  analyticsUrl: 'summary'
}

export const THANK_YOU_INFO: IRouteInfo = {
  name: ROUTE_NAMES.THANK_YOU,
  analyticsTitle: 'Thank You',
  analyticsUrl: 'thankyou'
}

export const ERROR_PAGE_INFO: IRouteInfo = {
  name: ROUTE_NAMES.ERROR_PAGE,
  analyticsTitle: 'Error',
  analyticsUrl: 'errorPage'
}

export const CANCEL_INFO: IRouteInfo = {
  name: ROUTE_NAMES.CANCEL,
  analyticsTitle: 'Cancel',
  analyticsUrl: 'cancel'
}

export const MANAGE_BOOKING_INFO: IRouteInfo = {
  name: ROUTE_NAMES.MANAGE_BOOKING,
  analyticsTitle: 'Manage Booking',
  analyticsUrl: 'manageBooking'
}

export const PAYMENTS_INFO: IRouteInfo = {
  name: ROUTE_NAMES.PAYMENTS,
  analyticsTitle: 'Payment Details',
  analyticsUrl: 'payments'
}

export const PAYMENT_COMPLETE_INFO: IRouteInfo = {
  name: ROUTE_NAMES.PAYMENT_COMPLETE,
  analyticsTitle: 'Payment Confirmation',
  analyticsUrl: 'paymentComplete'
}
