
// action type
export enum BookingActionsTypes {
  CHANGED_COVERS_COUNT = 'CHANGED_COVERS_COUNT',
  CHANGED_BOOKING_DATE = 'CHANGED_BOOKING_DATE',
  CHANGED_BOOKING_TIME = 'CHANGED_BOOKING_TIME',
  SERVICE_SCHEDULE_LOADING = 'SERVICE_SCHEDULE_LOADING',
  ALL_VENUE_SCHEDULE_LOADING = 'ALL_VENUE_SCHEDULE_LOADING',
  ALL_VENUE_SCHEDULE_FAILED = 'ALL_VENUE_SCHEDULE_FAILED',
  SERVICE_SCHEDULE_SUCCESS = 'SERVICE_SCHEDULE_SUCCESS',
  ALL_VENUE_SCHEDULE_SUCCESS = 'ALL_VENUE_SCHEDULE_SUCCESS',
  API_CALLED = 'API_CALLED',
  ALL_VENUE_TIMES = 'ALL_VENUE_TIMES',
  SELECTED_OTHER_VENUE_TIME = 'SELECTED_OTHER_VENUE_TIME',
  SERVICE_SCHEDULE_FAILED = 'SERVICE_SCHEDULE_FAILED',
  CHANGED_ACTIVE_SERVICE = 'CHANGED_ACTIVE_SERVICE',
  CHANGED_ACTIVE_SECTION = 'CHANGED_ACTIVE_SECTION',
  SET_ACTIVE_SECTION_FROM_ACTIVE_SERVICE = 'SET_ACTIVE_SECTION_FROM_ACTIVE_SERVICE',
  CHANGED_CALENDER_VIEW = 'CHANGED_CALENDER_VIEW',
  IS_UPSELL_PRESENT = 'IS_UPSELL_PRESENT',
  CHANGED_CUSTOMER_DETAILS = 'CHANGED_CUSTOMER_DETAILS',
  SAVE_BOOKING_SUCCESS = 'SAVE_BOOKING_SUCCESS',
  SAVE_BOOKING_FAIL = 'SAVE_BOOKING_FAIL',
  CONFIRM_BOOKING_SUCCESS = 'CONFIRM_BOOKING_SUCCESS',
  CONFIRM_BOOKING_FAIL = 'CONFIRM_BOOKING_FAIL',
  PROMOTION_CODE_VERIFIED = 'PROMOTION_CODE_VERIFIED',
  PROMOTION_CODE_FAIL = 'PROMOTION_CODE_FAIL',
  UPDATE_HAS_PROMOTION_CODE = 'UPDATE_HAS_PROMOTION_CODE',
  EDIT_BOOKING_FAIL = 'EDIT_BOOKING_FAIL',
  CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS',
  CANCEL_BOOKING_FAIL = 'CANCEL_BOOKING_FAIL',
  DELETE_BOOKING_SUCCESS = 'DELETE_BOOKING_SUCCESS',
  PREPARE_EWAY_PAYMENT_SUCCESS = 'PREPARE_EWAY_PAYMENT_SUCCESS',
  PREPARE_EWAY_PAYMENT_FAIL = 'PREPARE_EWAY_PAYMENT_FAIL',
  EWAY_PAYMENT_SUCCESS = 'EWAY_PAYMENT_SUCCESS',
  PREAUTH_SUCCESS = 'PREAUTH_SUCCESS',
  BOOKING_ERROR = 'BOOKING_ERROR',
  STRIPE_LOADED = 'STRIPE_LOADED',
  STRIPE_PAYMENT_SUCCESS = 'STRIPE_PAYMENT_SUCCESS',
  STRIPE_PAYMENT_FAIL = 'STRIPE_PAYMENT_FAIL',
  PAYMENT_SUMMARY_FAIL = 'PAYMENT_SUMMARY_FAIL',
  BOOKING_EDIT_EXPIRED = 'BOOKING_EDIT_EXPIRED',
  SESSION_REMAINING_TIME_UPDATED = 'SESSION_REMAINING_TIME_UPDATED',
  SESSION_FINISHED = 'SESSION_FINISHED',
  TIME_EXPIRED = 'TIME_EXPIRED',
  UPDATED_BOOKING_PAYMENT_TYPE = 'UPDATED_BOOKING_PAYMENT_TYPE',
  SHOW_PAYMENT_MISMATCH_ERROR = 'SHOW_PAYMENT_MISMATCH_ERROR',
  HIDE_UPSELL_POPUP = 'HIDE_UPSELL_POPUP',
  SEGREGATE_SELECTED_MENU_OPTION_UPSELL = 'SEGREGATE_SELECTED_MENU_OPTION_UPSELL',
  HAVE_ACCEPTED_VERIFICATION = 'HAVE_ACCEPTED_VERIFICATION',
  HAVE_MINIMUM_PAX = 'HAVE_MINIMUM_PAX',
}
