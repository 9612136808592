import { wrapperStyleType, IWidgetTheme, blockNavType } from "app/models";

export interface IDispatchFromProps {
  handleNavPressed: (type: footerNavTypes) => void;
  handleDisabledNextPressed?: () => void;
  handleCancelBooking: () => void;
}

export interface IStateFromProps {
  theme: IWidgetTheme;
  wrapperStyle: wrapperStyleType;
  nextEnabled: boolean;
  prevEnabled: boolean;
  nextType: footerNavTypes;
  blockNav: blockNavType;
  venuePhone: string;
  showNextLoader: boolean;
}


export enum footerNavTypes {
  next = 'next',
  book = 'book',
  pay = 'pay',
  preauth = 'preauth',
  prev = 'prev',
  standby = 'standby'
}
