import React, { useState } from 'react';
import { IMockMenu } from './types';
import { Drawer, List, ListItem, ListItemText, Divider, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { MocksService } from 'app/services/mocks/mocks.service';
import { LocationService } from 'app/services/location/location.service';

const NS = 'MockMenu';

export default function MockMenu({stripe, isOpen, venueId, onClose}: IMockMenu) {
  
  const [openEwayMenu, setOpenEwayMenu] = useState<boolean>(false);
  const [openStripeMenu, setOpenStripeMenu] = useState<boolean>(false);
  const [openBookingMenu, setOpenBookingMenu] = useState<boolean>(false);
  const [openPromoCodeMenu, setOpenPromoCodeMenu] = useState<boolean>(false);
  const [openSessionMenu, setOpenSessionMenu] = useState<boolean>(false);
  
  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <List>
        <ListItem button onClick={() => setOpenEwayMenu(!openEwayMenu)}>
          <ListItemText primary='Eway Mocks'/>
          {openEwayMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openEwayMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText primary='Success (for http env)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockEwayPaymentData();
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='404 Error (pay now)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockEwayPaymentData(404);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='404 Error (payment summary)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockEwayPaymentData(null, 404);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='Timeout Error (process Eway)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockEwayPaymentData(null, null, 'timeout');
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='Server Error (process Eway)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockEwayPaymentData(null, null, 'serverError');
                }} />
            </ListItem>
          </List>
        </Collapse>
      </List>

      <Divider />
      
      <List>
        <ListItem button onClick={() => setOpenStripeMenu(!openStripeMenu)}>
          <ListItemText primary='Stripe Mocks'/>
          {openStripeMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openStripeMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText primary='Error (createPaymentMethod)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockStripePaymentData(stripe);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='404 Expired Booking (pay now - full/deposit)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockStripePaymentData(null, 400);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='400 Payment (pay now - full/deposit)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockStripePaymentData(null, 400);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='399 Payment (pay now - full/deposit)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockStripePaymentData(null, 399);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='404 Expired Booking (pre-auth)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockStripePaymentData(null, null, 404);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='400 Payment Error (pre-auth)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockStripePaymentData(null, null, 400);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='399 Unknown Error (pre-auth)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockStripePaymentData(null, null, 399);
                }} />
            </ListItem>
          </List>
        </Collapse>
      </List>

      <Divider />

      <List>
        <ListItem button onClick={() => setOpenBookingMenu(!openBookingMenu)}>
          <ListItemText primary='Booking Mocks'/>
          {openBookingMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openBookingMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText primary='500 Error (getSchedule)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockGetSchedule(venueId, 500);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='"bookingCancelled" message (cancelBooking)' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockCancelBooking(venueId, true);
                }} />
            </ListItem>
          </List>
        </Collapse>
      </List>

      <Divider />

      <List>
        <ListItem button onClick={() => setOpenPromoCodeMenu(!openPromoCodeMenu)}>
          <ListItemText primary='Promo code Mocks'/>
          {openPromoCodeMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openPromoCodeMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText primary='Success' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockSuccessPromoCode();
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='404 Expired Booking' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockErrorPromoCode(404);
                }} />
            </ListItem>
            <ListItem button>
              <ListItemText primary='400 bad request' onClick={() => {
                  MocksService.setMockAdapter();
                  MocksService.setMockErrorPromoCode(400);
                }} />
            </ListItem>
          </List>
        </Collapse>
      </List>

      <Divider />

      <List>
        <ListItem button onClick={() => setOpenSessionMenu(!openSessionMenu)}>
          <ListItemText primary='Session Mocks'/>
          {openSessionMenu ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openSessionMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button>
              <ListItemText primary='Dev env (shows session timer)' onClick={() => {
                  LocationService.forceDev = true;
                }} />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Drawer>
  )
}