import React from 'react';
import style from './style.module.scss';
import classNames from 'classnames';
import { Typography, createStyles, Color, makeStyles } from '@material-ui/core';
import { wrapperStyleType, IWidgetTheme } from 'app/models';
import { renderIf } from 'app/services/utils/utils.service';
import { IStateFromProps, IDispatchFromProps } from './types';
import { ThemeColorsService } from 'app/services/theme/themeColors.service';
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";

const NS = 'MainHeader';

const useStyles = makeStyles((theme: IWidgetTheme) => {
  const {secondary, textColor2} = ThemeColorsService.getCommonThemeStyles(theme);

  return createStyles({
    venueTitle: {
      color: textColor2
    },
    sessionBanner: {
      backgroundColor: secondary.main,
      color: theme.palette.getContrastText(secondary.main)
    }
  })
});


export default function MainHeader({
    isLoading, theme, venue, booking, viewTime, wrapperStyle, businessName, heading, centerLogo,
    appVersion, logoUrl, showAppVersion, isStyleGuide, sessionRemainingTime, isDarkAndLoading, hideHeader
  }: IStateFromProps & IDispatchFromProps) {

  const classes = useStyles({theme});

  // 'hideHeader' forces the 'stacked' or 'narrow' style for the expiry strip
  const isLandscape = !IframeResizerService.isStacked(wrapperStyle) && !hideHeader;
  const isStandard = wrapperStyle === wrapperStyleType.standard && !hideHeader;

  return (
    <header className={style.root}>
      <div className={classNames({
        [style.container]: true,
        [style.containerIsHidden]: hideHeader,
        [style.containerIsLandscape]: isLandscape,
        [style.containerHasStackedBanner]: !isLandscape && !!sessionRemainingTime,
        [style.containerHasCenteredLogo]: centerLogo
      })}>
        {logoUrl ?
          <div
            data-testid="main-header-logo"
            className={classNames({
              [style.logoWrap]: true,
              [style.logoWrapIsHidden]: hideHeader,
              [style.logoWrapIsLandscape]: isLandscape,
              [style.logoWrapIsStandard]: isStandard
            })}
          >
            <img src={logoUrl} alt={venue ? venue.name : businessName} />
          </div>
          :
          venue ? '' :
          <Typography
            data-testid="main-header-sub-title-1"
            variant="subtitle1"
            className={classNames({
              [style.businessNameIsHidden]: hideHeader
            })}
          >
            {businessName}
          </Typography>
        }

        {venue ?
          <Typography className={classNames({
            [style.title]: true,
            [style.titleIsHidden]: hideHeader,
            [style.titleIsForcedLight]: isDarkAndLoading,
            [style.titleIsLandscape]: isLandscape,
            [style.titleIsLandscapeHasLogo]: !!logoUrl && isLandscape,
            [style.titleIsStackedNoLogo]: !logoUrl && !isLandscape,
          })} variant="subtitle1" data-testid="main-header-sub-title-2">
            Booking at <span className={classNames({
              [classes.venueTitle]: true,
              [style.venueTitleIsForcedLight]: isDarkAndLoading
            })}>{venue.name}</span>
            {booking ?
              <span>
                {logoUrl ? <br /> : ' '}
                {booking.viewDate ?
                  <span>on {
                    // showing a shorter version for mobile/stacked version
                    isLandscape ? booking.viewDate : booking.moment.format("ddd Do MMM YYYY")
                  }</span>
                  : ''
                }
                {booking.covers ?
                  <span> for {booking.covers} {booking.covers === 1 ? 'person' : 'people'}</span>
                  : ''
                }
                {viewTime ?
                  <span> at {viewTime}</span>
                  : ''
                }
              </span>
              : ''
            }
          </Typography>
          :
          (isLoading || centerLogo ? '' :
            <Typography className={classNames({
              [style.title]: true,
              [style.titleIsHidden]: hideHeader
            })} variant="subtitle1">
              { heading || (isStyleGuide ? 'Style Guide' : 'New Booking')}
            </Typography>
          )
        }
        <span className={classNames({
          [style.rightSide]: true,
          [style.rightSideIsHidden]: centerLogo,
          [style.rightSideIsStacked]: !isLandscape,
          [style.rightSideHasLogo]: !!logoUrl,
          [style.rightSideHasLogoIsLandscape]: !!logoUrl && isLandscape,
          [style.rightSideHasSessionText]: !!sessionRemainingTime
        })} data-testid="main-header-app-version">
          {renderIf(showAppVersion && !sessionRemainingTime, () => (
            <Typography className={classNames({
              [style.appVersion]: true,
              [style.appVersionIsStacked]: !isLandscape,
              [style.appVersionHasSessionText]: !!sessionRemainingTime && isLandscape
            })} variant="body1" data-testid="main-header-app-version-text">
              {appVersion}
            </Typography>
          ))}

          {renderIf(sessionRemainingTime, () => (
            <div className={classNames({
              [style.sessionRemaining]: true,
              [style.sessionRemainingIsRounded]: hideHeader,
              [style.sessionRemainingIsStacked]: !isLandscape,
              [classes.sessionBanner]: true
            })} data-testid="main-header-expire-time">
              <Typography variant="body1" className={style.sessionRemainingText} >
                Your booking will <span className={style.hideBreak}><br /></span>
                expire in: {sessionRemainingTime}
              </Typography>
            </div>
          ))}
          </span>
      </div>
    </header>
  )
}
