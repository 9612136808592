/* eslint-disable */

// @ts-ignore
import * as queryString from 'query-string-es5';

import { LocationService } from "../location/location.service";
import { GoogleEventCategory, AnalyticsReporter, GoogleTagManagerEmbed } from "./analytics.types";
import { IVenue, analyticsParams } from "app/models";


const NS = 'AnalyticsService';

/**
 * When widget is embedded in iframe, analytics cookies will be blocked without this flag
 * REF: https://www.simoahava.com/analytics/cookieflags-field-google-analytics/
 */
const GAFlags = 'samesite=none;secure';

class AnalyticsService {

  private googleApi: string;
  private facebookApi: string;
  private activeVenue: IVenue;
  private reporters: AnalyticsReporter[] = [];
  private fullyInitialized = false;
  private usingGoogleBubbleUp = false;
  private googleReferrerValue = '';

  init(activeVenue: IVenue = null) {

    if (this.fullyInitialized) {
      return;
    }

    // don't want tests to call this
    if (typeof window == 'undefined') {
      return;
    }

    if (!activeVenue) {
        return;
    }

    this.fullyInitialized = true;

    // Start venue level setup
    this.activeVenue = activeVenue;

    const win: any = (window as any);
    const isEmbeddedIFrame = typeof win.parent !== "undefined"
      && typeof win.parent.postMessage !== "undefined"
      && parent !== win;


    /**
     * If widget is embedded in an iFrame and has param `analytics=bubble` pass the data up to the parent page for tracking.
     * Origin and 'type' must be checked on parent page.
     * See LayoutView.tsx for example implementation.
     */
    const params: any = queryString.parse(location.search) as unknown;
    if (isEmbeddedIFrame) {
      this.usingGoogleBubbleUp = params.analytics === analyticsParams.bubble; //

      if (this.usingGoogleBubbleUp) {
        this.reporters.push(AnalyticsReporter.BUBBLE);
      }
    }

    if (!this.usingGoogleBubbleUp && params.analytics) {
      this.googleReferrerValue = params.analytics;
    }
    this.lookForHashGoogleReferrerValue();

    this.facebookInit();

    if (!activeVenue.analytics.googleApi) {
        return;
    }

    this.googleApi = activeVenue.analytics.googleApi;

    /**
     * Google tag manager is not backwards compatible so
     * we need a cheeky string search to direct which
     * Analytics type to use
     */
    if (this.googleApi.indexOf('GTM-') === 0) {
        this.googleTagManagerInit();
    }

    if (this.googleApi.indexOf('UA-') === 0) {
        this.googleAnalyticsInit();
    }
  }

  /**
   * Waits for a hash change to get the referrer value
   */
  private lookForHashGoogleReferrerValue(): void {
    const referrer = window.location.hash.split('referrer=')[1];
    if (referrer) {
      this.googleReferrerValue = referrer;
    } else {
      window.addEventListener("hashchange", () => {
        const referrer = window.location.hash.split('referrer=')[1];
        this.googleReferrerValue = referrer;
        console.log(NS, 'lookForHashGoogleReferrerValue', this.googleReferrerValue);
      });
    }
  }


  pageView(url: string, pageName: string, venueName: string): void {
    this.facebookPageView(pageName);
    this.googlePageView(url, pageName, venueName);
  }

  event(category: GoogleEventCategory, name: string, venueName: string, data?: any): void {
    this.facebookEvent(name);
    this.googleEvent(category, name, venueName, data);
  }

  private googleAnalyticsInit(): void {

    if (this.usingGoogleBubbleUp || !this.googleApi || this.isActive(AnalyticsReporter.GA)) {
      return;
    }

    this.reporters.push(AnalyticsReporter.GA);

    const s = document.createElement('script');
    s.async = true;
    s.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.googleApi;

    const body = document.getElementsByTagName('body')[0];
    body.appendChild(s);

    // @ts-ignore
    window.dataLayer = window.dataLayer || [];

    this.gtag('js', new Date());
    this.gtag('config', this.googleApi, {
      // Dont send page view to analytics automatically handle these as custom events
      'send_page_view': false,
      'cookie_flags': GAFlags
    });
  }

  private googleTagManagerInit(): void {

    if (this.usingGoogleBubbleUp || !this.googleApi || this.isActive(AnalyticsReporter.GTM)) {
      return;
    }

    this.reporters.push(AnalyticsReporter.GTM);

    const w = window;
    const d = document;
    const s = 'script';
    const l = 'dataLayer';

    // Code copied from Tag manager docs
    // @ts-ignore
    w[l] = w[l] || [];
    // @ts-ignore
    w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js'
    });

    const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + this.googleApi + dl;
    f.parentNode.insertBefore(j, f);
  }

  private facebookInit() {

    this.facebookApi = this.activeVenue.analytics.facebookApi;

    if (!this.facebookApi || this.isActive(AnalyticsReporter.Facebook)) {
        return;
    }

    this.reporters.push(AnalyticsReporter.Facebook);

    // Facebook init
    // @ts-ignore
    ! function (f: any, b: any, e: any, v: any, n?: any, t?: any, s?: any) {
        if (f.fbq) {
            return;
        }
        n = f.fbq = function () {

            if (n.callMethod) {
                n.callMethod.apply(n, arguments);
            } else {
                n.queue.push(arguments);
            }
        };
        if (!f._fbq) {
            f._fbq = n;
        }

        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // @ts-ignore
    window.fbq('init', this.facebookApi);
    // @ts-ignore
    window.fbq('track', 'PageView');
  }

  private googlePageView(url: string, pageName: string, venueName: string): void {
    if ((!this.usingGoogleBubbleUp && !this.googleApi) || !pageName) {
      return;
    }

    if (LocationService.isDevEnvironment()) {
      console.log(NS, 'googlePageView', url, pageName, venueName);
    }

    if (!this.isActive(AnalyticsReporter.GA) && !this.isActive(AnalyticsReporter.GTM) && !this.isActive(AnalyticsReporter.BUBBLE)) {
      return;
    }

    const data: any = {
      'event': pageName, // Important! "event" property is needed for GTM to recognise custom event names properly. Otherwise will show as generic "Message" event, which Tags cannot be attached to.
      'event_category': 'Page View',
      'event_label': venueName,
      'cookie_flags': GAFlags,
      'page_title': pageName,
      'page_url': url
    };

    if (this.googleReferrerValue) {
      data.referrer = this.googleReferrerValue;
    }

    this.gtag('event', pageName, data);

    this.gtag('config', this.googleApi, {
      'page_title': pageName,
      'page_path': url,
      'event_label': venueName,
      'cookie_flags': GAFlags
    });
  }

  private googleEvent(category: GoogleEventCategory, name: string, venueName: string, data?: any): void {

    if (LocationService.isDevEnvironment()) {
      console.log(NS, 'googleEvent', category, name, venueName, data);
    }

    if (!this.isActive(AnalyticsReporter.GA) && !this.isActive(AnalyticsReporter.GTM) && !this.isActive(AnalyticsReporter.BUBBLE)) {
      return;
    }

    const config: any = {
      'event': name, // Important! "event" property is needed for GTM to recognise custom event names properly. Otherwise will show as generic "Message" event, which Tags cannot be attached to.
      'event_category': category,
      'event_action': name,
      'event_label': venueName,
      'cookie_flags': GAFlags
    };

    if (this.googleReferrerValue) {
      config.referrer = this.googleReferrerValue;
    }

    if (data) {
      config.event_data = data;
      config.event_value = JSON.stringify(data);
    }

    this.gtag('event', name, config);
  }

  private facebookPageView(pageName: string) {
    if (!this.isActive(AnalyticsReporter.Facebook)) {
      return;
    }

    if (LocationService.isDevEnvironment()) {
      console.log(NS, 'facebookPageView', pageName);
    }

    // @ts-ignore
    window.fbq('trackCustom', pageName, {
      content_category: this.activeVenue ? this.activeVenue.name : null
    });
}

  private facebookEvent(name: string) {
    if (!this.isActive(AnalyticsReporter.Facebook)) {
      return;
    }
    if (LocationService.isDevEnvironment()) {
      console.log(NS, 'facebookEvent', name);
    }
    // @ts-ignore
    window.fbq('trackCustom', name, {
      content_category: this.activeVenue ? this.activeVenue.name : null
    });
  }

  private gtag(...args: any) {

    /**
     * If widget is embedded in an iFrame and has param `analytics=bubble` pass the data up to the parent page for tracking.
     * Origin and 'type' must be checked on parent page.
     * See LayoutView.tsx for example implementation.
     */
    if (this.usingGoogleBubbleUp) {

      if (args[0] === 'event') {
        try {
          const data: string = JSON.stringify({
            type: GoogleTagManagerEmbed.TYPE, // this type must be checked on containing page
            event: {
              ...args[2]
            }
          });
          (window as any).parent.postMessage(data, "*");
        } catch (e) {
          console.log(e);
        }
      }

      return;
    }

    if (arguments.length > 3) {
      console.warn(NS, `You are trying to send ${arguments.length} parameters to ga/gtm, but it will silently fail if this happens. Reducing to 3 parameters.`);
      // @ts-ignore
      Array.prototype.splice.call(arguments, arguments.length - 3);
    }

    /**
     * If widget is a standalone page, just uses embedded google analytics or Google Tag Manager.
     */
    (window as any).dataLayer.push(arguments);
  }

  private isActive(key: AnalyticsReporter) {
    return this.reporters.indexOf(key) !== -1;
  }
}


// creates a singleton instance to export
const instance = new AnalyticsService();
export { instance as AnalyticsService };
