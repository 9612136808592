import appValues from 'app/constants/appValues';
import { ISimpleVenue } from "app/components/Venues/interfaces";
import { IAccountDetails } from "../account/account.types";
import { wrapperStyleType } from "app/models";
import { LocationService } from "../location/location.service";
import {IframeResizerService} from "app/services/iframeResizer/iframeResizer.service";

const LOGO_HEIGHT_MOBILE = 40;
const LOGO_HEIGHT_DESKTOP = 80;

export class ImageService {

  static isJPG(url: string): boolean {
    let ext = '.jpg';
    let index: number = url.toLowerCase().lastIndexOf(ext);
    if (index === -1) {
      ext = '.jpeg';
      index = url.toLowerCase().lastIndexOf(ext);
    }
    if (index === -1) {
      return false;
    }

    return this.confirmExtention(url, ext, index);
  }

  static isPNG(url: string): boolean {
    const ext = '.png';
    const index: number = url.toLowerCase().lastIndexOf(ext);
    if (index === -1) {
      return false;
    }

    return this.confirmExtention(url, ext, index);
  }

  static getLogoUrl(venue: ISimpleVenue, wrapperStyle: wrapperStyleType, accountDetails: IAccountDetails): string {
    const logoUrl = this.getLogoUrlFromVenueOrAccount(venue, accountDetails);
    if (!logoUrl) {
      return null;
    }

    // support old version
    if (!LocationService.isSSH(logoUrl)) {
      return appValues.APIBASE + logoUrl;
    }

    if (ImageService.isJPG(logoUrl) || ImageService.isPNG(logoUrl)) {
      return `${logoUrl}?h=${IframeResizerService.isStacked(wrapperStyle) ? LOGO_HEIGHT_MOBILE : LOGO_HEIGHT_DESKTOP}`;
    }

    return null;
  }

  private static getLogoUrlFromVenueOrAccount(venue: ISimpleVenue, accountDetails: IAccountDetails): string {
    if (venue && venue.logoUrl) {
      return venue.logoUrl;
    }
    if (accountDetails && accountDetails.logoUrl) {
      return accountDetails.logoUrl;
    }
    return null;
  }

  private static stripQueryString(url: string): string {
    return url.split('?')[0];
  }

  /**
   * checks that the assumed extention is actually the last characters of the url
   * @param url - url to check
   * @param ext - extention to check
   * @param index - index of the start of the extention
   */
  private static confirmExtention(url: string, ext: string, index: number): boolean {
    return this.stripQueryString(url).length - index === ext.length;
  }
}
