import React, { ReactElement } from 'react';
import {from, Observable} from "rxjs";
import axios, {AxiosAdapter, AxiosInstance, AxiosRequestConfig} from 'axios';
import {IResponse} from 'app/containers/App/types';
import {setupCache} from 'axios-cache-adapter';
import {bookingErrorType} from "../error/error.types";
import appValues from "app/constants/appValues";
import {
  IFinilisePayment3DSecureParams,
  IFinalisePayment3DSecurePFParams,
  IFunctionPaymentSummaryResponse,
  IHasPromoCodeResponseData,
  IPaymentSummaryParams,
  IPaymentSummaryResponse,
  IProcessEwayPaymentResponse,
  IPromoCodeResponse,
  ISavePreAuthData,
  ISavePreAuthResponse,
  ISetupPreAuth3DData,
  ISetupPreAuth3DResponse,
  IFinalizePreAuth3DData,
  IFinalizePreAuth3DResponse
} from "./payment.types";
import {IPaymentDetailsGenericData} from "app/components/PaymentDetailsGeneric/types";
import {
  bookingStatusType,
  IBooking,
  IBookingMenuOption,
  IBookingPayment,
  IPrivateFunction
} from "../booking/booking.types";
import {map} from "rxjs/operators";
import {
  IPayNowParams,
  IPayNowResponse,
  IPrepareEwayFunctionResponse
} from "../client/client.types";
import {IGroupedTablesBoxGroup, IGroupedTablesBoxItem} from "app/components/GroupedTablesBox/types";
import {IntlService} from "../intl/intlService";
import {sortBy} from 'lodash';
import { noCase } from 'change-case';
import ExternalLinkContainer from 'app/components/ExternalLink/container';
import { externalLinkType } from 'shared-components/external-link/types';
import {IPaymentSummaryMenuOption} from "app/components/PaymentSummary/types";
import {
  IServicePaymentOption,
  ISimpleBookingOption,
  servicePaymentType
} from "shared-types/index";

const NS = 'PaymentService';

// @toDo: check cache time required. severity: high
const cache = setupCache({
  maxAge: 24 * 60 * 60 * 1000 // cached for 1 day
});

// Create `axios` instance passing the newly created `cache.adapter`
const api: AxiosInstance = axios.create({
  adapter: cache.adapter as AxiosAdapter
});

/**
 * Should only get called once per session/app load.
 * Used for better SEQ logging.
 * Same thing also in ClientService.
 */
const requestInterceptor = (config: AxiosRequestConfig) => {
  config.headers['X-NBI-CorrelationId'] = appValues.GLOBAL_SESSION_CORRELATION_ID;
  config.headers['X-NBI-Source'] = 'widget2';
  return config;
};
api.interceptors.request.use(requestInterceptor);

export class PaymentService {

  static PAYMENT_SUMMARY_URL = `${appValues.APIBASE}/bookings/payments/completed`;
  static PAY_NOW_URL = `${appValues.APIBASE}/bookings/payments/paynow`;
  static PRE_AUTH_URL = `${appValues.APIBASE}/bookings/payments/preauth`;
  static SETUP_PRE_AUTH_STRIPE3D_URL = `${appValues.APIBASE}/bookings/payments/stripe-3d-preauth/setup`;
  static FINALIZE_PRE_AUTH_STRIPE3D_URL = `${appValues.APIBASE}/bookings/payments/stripe-3d-preauth/finalize`;
  static PROMO_CODE_URL = `${appValues.APIBASE}/bookings/payments/apply-promotion-code`;
  static HAS_PROMO_CODE_URL = `${appValues.APIBASE}/bookings/payments/has-promotion-code`;



  static getAxiosInstance(): AxiosInstance {
    return api;
  }

  static isAmex(cardNumber: string): boolean {
    return cardNumber[0] === '3';
  }

  /**
   * Rejects string with anything but numbers
   */
  static validateNumbersOnly(val: string): boolean {
    return !/^[0-9]*$/.test(val);
  }

  /**
   * Adds errors for CVC, talking into account different rules for AMEX
   */
  static addCVCValidationErrors(amexCardEntered: boolean, cvc: string, errors: any): void {
    const amexCVCLength = 4;
    const otherCVCLength = 3;

    if (!cvc) {
      errors.cvc = 'You forgot to enter your CVC number';
    } else if (this.validateNumbersOnly(cvc)) {
      errors.cvc = 'Please only enter digits';
    } else {
      if (cvc.length < (amexCardEntered ? amexCVCLength : otherCVCLength)) {
        errors.cvc = 'Your number is too short';
      } else if (cvc.length > (amexCardEntered ? amexCVCLength : otherCVCLength)) {
        errors.cvc = 'Your number is too long';
      }
    }
  }

  /**
   * Adds errors for card number, talking into account different rules for AMEX
   */
  static addCardNumberValidationErrors(amexCardEntered: boolean, cardNumber: string, errors: any): void {
    const amexCardNumberLength = 15;
    const otherCardNumberLength = 16;

    if (!cardNumber) {
      errors.cardNumber = 'You forgot to enter your card number';
    } else if (this.validateNumbersOnly(cardNumber)) {
      errors.cardNumber = 'Please only enter digits';
    } else {
      if (cardNumber.length < (amexCardEntered ? amexCardNumberLength : otherCardNumberLength)) {
        errors.cardNumber = 'Your number is too short';
      } else if (cardNumber.length > (amexCardEntered ? amexCardNumberLength : otherCardNumberLength)) {
        errors.cardNumber = 'Your number is too long';
      }
    }
  }

  /**
   * Adds errors for expiry year or month
   */
  static addExpiryValidationErrors(expiry: string, type: 'Month' | 'Year', errors: any): void {
    if (!expiry) {
      errors[`expiry${type}`] = `You forgot to enter your expiry ${type.toLowerCase()}`;
    } else if (PaymentService.validateNumbersOnly(expiry)) {
      errors[`expiry${type}`] = 'Please only enter digits';
    } else {
      const value: number = parseInt(expiry, 10);
      let invalid: boolean = expiry.length !== 2;
      if (type === 'Month' && (value < 1 || value > 12)) {
        invalid = true;
      }

      if (invalid) {
        errors[`expiry${type}`] = `${type} is invalid`;
      }
    }
  }

  static paymentIntent3DSecure(bookingId: string, venueId: number, amountDue: number, booking: IBooking, fee: number): Observable<IResponse<string>> {
    const firstName : string = booking.customer.firstName;
    const lastName: string = booking.customer.lastName;
    const phone: string = booking.customer.phone;
    const emailId: string = booking.customer.email;
    const bookingFee: number = fee;
    const bookingDate: string = booking.viewDate;
    const paymentType: string = booking.payment.paymentType;
    const paymentAmount: number = booking.payment.amountDue;


    const bookingParam = {
      firstName,
      lastName,
      phone,
      emailId,
      bookingFee,
      bookingDate,
      paymentType,
      paymentAmount,
      bookingId
    };

    return from(
      api.post(`${appValues.APIBASE}/bookings/payments/venues/${venueId}/paymentintent`, { ...bookingParam})
    );
  }

  static paymentIntent3DSecureForPF(eventId: string, venueId: number, booking: IBooking, fee: number): Observable<IResponse<string>> {
    const firstName: string = booking.customer.firstName;
    const lastName: string = booking.customer.lastName;
    const phone: string = booking.customer.phone;
    const emailId: string = booking.customer.email;
    const bookingFee: number = fee;
    const bookingDate: string = booking.viewDate;
    const paymentType: string = booking.payment.paymentType;
    const paymentAmount: number = booking.payment.amountDue;
    const bookingId = eventId;


    const bookingParam = {
      firstName,
      lastName,
      phone,
      emailId,
      bookingFee,
      bookingDate,
      paymentType,
      paymentAmount,
      bookingId
    };


    return from(
      api.post(`${appValues.APIBASE}/bookings/payments/venues/${venueId}/paymentintent/event`, { ...bookingParam } )
    );
  }

  static beginStandbyPayment(bookingId: string, venueId: number): Observable<IResponse<string>> {
    return from(
      api.get(`${appValues.APIBASE}/bookings/venues/${venueId}/begin-standby-payment/${bookingId}`)
    );
  }

  static finilisePayment3DSecure(bookingId: string, venueId: number, TransactionId: string, AmountPaidId: number): Observable<IResponse<any>> {
    const params: IFinilisePayment3DSecureParams = {
      venueId,
      bookingId,
      TransactionId,
      AmountPaidId
    };
    return from(
      api.post(`${appValues.APIBASE}/bookings/payments/finilisePayment3Descure`, {...params})
    );
  }

  static finalisePayment3DSecureForPF(eventId: string, venueId: number, transactionId: string, amountPaidId: number): Observable<IResponse<any>> {
    const params: IFinalisePayment3DSecurePFParams = {
      venueId,
      eventId,
      transactionId,
      amountPaidId
    };
    return from(
      api.post(`${appValues.APIBASE}/bookings/payments/finalisePayment3Dsecpf`, { ...params })
    );
  }


  static getPaymentSummary(bookingId: string, venueId: number, accessCode: string): Observable<IPaymentSummaryResponse> {
    const params: IPaymentSummaryParams = {
      venueId,
      bookingId,
      accessCode
    };
    return from(
      api.get(this.PAYMENT_SUMMARY_URL, {params})
    );
  }


  static processEwayPayment(formEl: HTMLFormElement): Observable<IProcessEwayPaymentResponse> {
    return new Observable(observer => {

      if (!(window as any).eWAY) {
        console.warn(NS, 'Eway not loaded');
        observer.error(bookingErrorType.UKNOWN_USERPAYMENTERROR);
        return;
      }

      (window as any).eWAY.process(formEl, {
        autoRedirect: false,
        onComplete: (data: IProcessEwayPaymentResponse) => {
          if (data.Errors) {
            console.warn(NS, 'onComplete', 'data.Errors', data.Errors)
            // @toDo work out what to do with these based on codes above
            // errorService.processEwayError(data.Errors);
          } else {
            observer.next(data);
          }
        },
        onError: (error: any) => {
          console.warn(NS, 'onError', error);
          observer.error(bookingErrorType.paymentServerError);
        },
        onTimeout: (error: any) => {
          console.warn(NS, 'onTimeout', error);
          observer.error(bookingErrorType.paymentTimeout);
        }
      });
    });
  }

  static processStripePayment(
    stripe: stripe.Stripe,
    card: stripe.elements.Element,
    token: stripe.Token,
    paymentDetails: IPaymentDetailsGenericData
  ): Observable<stripe.PaymentMethodResponse> {

    return from(
      stripe.createPaymentMethod(
        'card',
        card,
        {
          billing_details: {
            name: paymentDetails.name
          }
        }
      )
    );
  }

  static getFunctionPayment(id: string, venueId: number): Observable<IPrivateFunction> {
    return from(
      api.get(appValues.APIBASE + '/bookings/venues/' + venueId.toString() + '/private-functions/' + id)
    ).pipe(
      map(({data}: any) => data as IPrivateFunction),
    );
  }

  static getFunctionPaymentSummary(venueId: number, eventId: string, accessCode: string = null, stripePaymentToken: string = null): Observable<IFunctionPaymentSummaryResponse> {
    return from(
      api.get(appValues.APIBASE + '/bookings/payments/event-completed/', {
        params: {
          venueId, eventId, accessCode, stripePaymentToken
        }
      })
    );
  }

  /**
   * The calls to eway and stripe are used to create a token, but the actual payment gets done on the back end
   * after this call gets made. FYI, using test credit cards that reject the payment, tend to always return 400 (Bad Request) responses.
   */
  static payNow(params: IPayNowParams): Observable<IPayNowResponse> {
    return from(
      api.post(this.PAY_NOW_URL, {...params})
    );
  }

  static eventPaynow(params: IPayNowParams): Observable<IPrepareEwayFunctionResponse> {
    return from(
      api.post(appValues.APIBASE + '/bookings/payments/event-paynow', {...params})
    )
  }


  static savePreAuth(params: ISavePreAuthData): Observable<ISavePreAuthResponse> {
    return from(
      api.post(this.PRE_AUTH_URL, {...params})
    );
  }


  static setupPreAuthStripe3D(params: ISetupPreAuth3DData): Observable<ISetupPreAuth3DResponse> {
    console.log("setupPreAuth3D");
    return from(
      api.post(this.SETUP_PRE_AUTH_STRIPE3D_URL, {...params})
    );
  }

  static finalizePreAuth3D(params: IFinalizePreAuth3DData): Observable<IFinalizePreAuth3DResponse> {
    console.log("finalizePreAuth3D");
    return from(
      api.post(this.FINALIZE_PRE_AUTH_STRIPE3D_URL, {...params})
    );
  }


  static applyPromoCode(promotionCode: string, bookingId: string, venueId: number): Observable<IPromoCodeResponse> {
    const params = {
      venueId,
      bookingId,
      promotionCode
    }
    return from(
      api.post(this.PROMO_CODE_URL, {...params})
    );
  }

  static hasPromoCode(bookingId: string, venueId: number): Observable<IResponse<IHasPromoCodeResponseData>> {
    const params = {
      venueId,
      bookingId
    }
    return from(
      api.post(this.HAS_PROMO_CODE_URL, {...params})
    );
  }


  private static formatCC(cardNumber: string): string {

    //if the card number is already formatted, just return as is
    if (cardNumber.indexOf(' ') !== -1 && cardNumber.indexOf('X') !== -1) {
      return cardNumber;
    }

    const maskedNumber: string = cardNumber.split('')
      .map((ch: string, i: number) => i < 12 ? 'X' : ch).join('');

    const spacedNumber: string = maskedNumber.split('')
      .map((ch: string, i: number) => i > 0 && i % 4 === 0 ? ` ${ch}` : ch).join('');

    return spacedNumber;
  }


  static getPaymentDetailGroup(payment: IBookingPayment, paymentDetails: IPaymentDetailsGenericData, currency: string): IGroupedTablesBoxGroup {

    if (!paymentDetails) {
      return {
        heading: 'Payment Details Error',
        items: []
      }
    }

    const items: IGroupedTablesBoxItem[] = [
      {order: 0, name: `Cardholder Name`, value: paymentDetails.name},
      {order: 1, name: `Credit Card Number`, value: PaymentService.formatCC(paymentDetails.cardNumber)},
      {
        order: 10, name: `Total Amount (${currency})`, value: (
          <span className="secondary-text">
          {IntlService.currencyValue(payment.amountDue, currency)}
        </span>
        )
      }
    ];

    if (payment.promotionCode) {
      items.push(
        {order: 2, name: `Promotion Code`, value: payment.promotionCode}
      )
    }

    if (payment.discountAmount) {
      items.push(
        {
          order: 3, name: `Required Payment (${currency})`, value: (
            <span className="secondary-text">
            {IntlService.currencyValue(payment.price, currency)}
          </span>
          )
        },
        {
          order: 4, name: `Discount Amount (${currency})`, value: (
            <span className="secondary-text">
            {IntlService.currencyValue(payment.discountAmount, currency)}
          </span>
          )
        }
      )
    }

    return {
      heading: 'Payment Details',
      items: sortBy(items, 'order')
    }
  }

  /**
   * Determines if booking is in Payment Pending state.
   * @param booking - you can use IBooking or a subset with props below
   */
  static isPaymentPending(status: bookingStatusType, payment: IBookingPayment): boolean {
    if (status === bookingStatusType.pendingPayment ||
      payment && (
        // there is a bug where a service can still have price when paymentType is noPayment, so must check both
        payment.price > 0 && payment.paymentType !== servicePaymentType.noPayment
      ) && (
        !payment.amountPaid ||
        payment.amountPaid < payment.price
      )) {
      return true;
    }
    return false;
  }

  static checkForUnconfirmedAndPaid(booking: IBooking): boolean {
    /**
     * 'isPaid' here checks if the booking has been fully paid for. This can include a pre-auth that the customer
     * has confirmed via the payment gateway - however, if the pre-auth has been released, then it is no longer considered
     * paid.
     */
    const isPaid = booking.payment
      && booking.payment.price > 0
      && booking.payment.amountPaid
      && booking.payment.amountPaid >= booking.payment.price;

    if (booking.status === bookingStatusType.unconfirmed && isPaid) {
      return true;
    }
    return false;
  }

  static getPaymentMessage(paymentType: servicePaymentType, currency: string, price: number, priceIsPerPerson = true, bgColor?: string): ReactElement {

    const isPreAuth = paymentType === servicePaymentType.preAuth;
    return <>A
      {!isPreAuth ? <> {noCase(paymentType)} </> : null}
      {isPreAuth ? <> credit card Booking Guarantee </> : null}
      <>of </>
      <span className="secondary-text">
        {IntlService.currencyValue(price, currency)}
      </span>
      &nbsp;{priceIsPerPerson ? 'per person' : '' } is required.
      {isPreAuth ?
        <> Funds will be verified, but not charged to your card at this time.&nbsp;
        <ExternalLinkContainer label={'View Booking Guarantee Policy.'} type={externalLinkType.preAuth} bgColor={bgColor} />
        </>
        : null
      }</>
  }

  static getBOForPaymentSummary(bookingOptions: IServicePaymentOption[], selectedMenuOptions: IBookingMenuOption[], cachedMenuOptionDetails: ISimpleBookingOption[]): IPaymentSummaryMenuOption[] {
    if (!bookingOptions) {
      return [];
    }

    return bookingOptions
      .reduce((acc, {id, label, price}) => {
        const selectedMenuOption: IBookingMenuOption = selectedMenuOptions.find(({menuOptionId}) => menuOptionId === id);

        if (selectedMenuOption && selectedMenuOption.quantity) {
          const {extras, quantity} = selectedMenuOption;
          const childOpts: IBookingMenuOption[] = [];
          if (extras) {
            if (extras.explicitChildMenuOptions) {
              extras.explicitChildMenuOptions.forEach(opts => {
                opts.forEach(o => childOpts.push(o));
              });
            }
            if (extras.implicitChildMenuOptions) {
              extras.implicitChildMenuOptions.forEach(o => childOpts.push(o));
            }
          }

          const childLineItems: IPaymentSummaryMenuOption[] = childOpts.length ? childOpts.map(({menuOptionId, quantity}) => {
            const details = cachedMenuOptionDetails.find(o => o.id === menuOptionId);
            return details && quantity ? {
              quantity,
              label: details.label,
              price: details.price
            } : null;
          }) : null;

          acc.push({label, price, childLineItems, quantity: selectedMenuOption.quantity});
        }

        return acc;
      }, []);
  }

  static getStandbyPaidNoTableMessage(phone: string, currency?: string, amountPaid = 0, isPreAuth = false): string {
    return `This booking is on the standby list and does not have an allocated table${
      amountPaid > 0
        ? `, but you have ${ isPreAuth ? 'pre-authorised' : 'made' } a payment of ${IntlService.currencyValueAsString(amountPaid, currency)}. Please check the status of your booking with the venue on ${phone}.`
        : `. Please confirm with the venue before making payment on ${phone}.`
    }`;
  }
}
