export interface IErrorResponseData {
  message: string; // comma separated string of errorCodes (eg V6101,V6106,V6110)
}

export interface IErrorResponse {
  statusText: string; // Server Error
  status: number; // 500
  data?: IErrorResponseData;
}

export enum bookingSuccessType {
  bookingConfirmed = 'bookingConfirmed'
}

export enum bookingErrorType {
  conflict = 'conflict',
  duplicate = 'duplicate',
  bookingServerError = 'bookingServerError',
  paymentNotSetup = 'paymentNotSetup',
  badRequest = 'badRequest',
  UKNOWN_USERPAYMENTERROR = 'UKNOWN_USERPAYMENTERROR',
  STANDBY_HAD_PAYMENT = 'STANDBY_HAD_PAYMENT',
  paymentServerError = 'paymentServerError',
  paymentTimeout = 'paymentTimeout',
  bookingExpired = 'bookingExpired',
  pageError = 'pageError',
  emptyError = 'emptyError',
  serverDown = 'serverDown',
  noAccount = 'noAccount',
  linkExpired = 'linkExpired',
  timeout = 'timeout',
  clientError = 'clientError',
  inactive = 'inactive',
  cancellationFail = 'cancellationFail',
  bookingExpiredBeforePayment = 'bookingExpiredBeforePayment',
  paymentError = 'paymentError',
  paymentAuthError = 'paymentAuthError',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  confirmationError = 'confirmationError',
  bookingAlreadyConfirmed = 'bookingAlreadyConfirmed',
  cancellationError = 'cancellationError',
  bookingAlreadyCancelled = 'bookingAlreadyCancelled',
  bookingCancelled = 'bookingCancelled',
  duplicateFunctionPayment = 'duplicateFunctionPayment',
  paymentProviderNotSetup = 'paymentProviderNotSetup',
  publishableKeyMissing = 'publishableKeyMissing',
  onlineBookingsOffMessage = 'onlineBookingsOffMessage',
  timeExpired = 'timeExpired',
  tooFarInAdvanceMessage = 'tooFarInAdvanceMessage'
}

export enum bookingErrorMessageType {
  serverError = 'serverError',
  noAccount = 'noAccount',
  linkExpired = 'linkExpired',
  timeout = 'timeout',
  paymentTimeout = 'paymentTimeout',
  clientError = 'clientError',
  noTables = 'noTables',
  timeNoLongerAvailableMessage = 'timeNoLongerAvailableMessage',
  bookingError = 'bookingError',
  inactive = 'inactive',
  paymentError = 'paymentError',
  cancellationFail = 'cancellationFail',
  confirmationError = 'confirmationError',
  bookingAlreadyConfirmed = 'bookingAlreadyConfirmed',
  cancelBookingError = 'cancelBookingError',
  bookingAlreadyCancelled = 'bookingAlreadyCancelled',
  functionPayment = 'functionPayment',
  paymentProviderNotSetup = 'paymentProviderNotSetup',
  publishableKeyMissing = 'publishableKeyMissing',
  emptyError = 'emptyError',
  onlineBookingsOffMessage = 'onlineBookingsOffMessage',
  tooFarInAdvanceMessage = 'tooFarInAdvanceMessage'
}

export interface IBookingErrorMinimal {
  heading: string;
  messageType: bookingErrorMessageType;
  name: bookingErrorType;
  message: string;
  buttonText: string;
}

export interface IBookingError extends IBookingErrorMinimal {
  data: string;
  status: number;
}

export interface IEwayErrorCode {
  code: string;
  description: string;
}

export interface IStripePaymentError {
  stripeError: stripe.Error; // comes from 'stripe.createPaymentMethod'
  backEndError: IErrorResponse; // comes from back end 'paynow'
}
