import { StateDeclaration } from "@uirouter/react";

export interface IRouteInfo {
  name: string;
  url?: string; // only needed for external routes, such as edit/cancel - or the style guide
  analyticsTitle?: string;
  analyticsUrl?: string;
}


export interface IRouteState extends IRouteInfo {
  component: (props: any) => React.ReactElement;
}


export enum ROUTE_NAMES {
  SETUP = 'setup',
  SITTING = 'sitting',
  VENUES = 'venues',
  CUSTOMER_DETAILS = 'customerDetails',
  SUMMARY = 'summary',
  THANK_YOU = 'thankyou',
  ERROR_PAGE = 'errorPage',
  CANCEL = 'cancel',
  MANAGE_BOOKING = 'manageBooking',
  PAYMENTS = 'payments',
  PAYMENT_COMPLETE = 'paymentComplete',
}

// EXTERNAL 'ACTION' URLS
export const CONFIRM_URL = '/confirm';
export const MANAGE_BOOKING_URL = '/manage-booking';
export const EDIT_URL = '/edit';

/**
 * This is all the possible 'step' routes (for back/next nav), however, they
 * will be modified in `state.stepRoutes` depending on various conditions.
 */
export const STEP_ROUTES: ROUTE_NAMES[] = [
  ROUTE_NAMES.SETUP,
  ROUTE_NAMES.VENUES, // removed when venue provided in url
  ROUTE_NAMES.SITTING,
  ROUTE_NAMES.CUSTOMER_DETAILS,
  ROUTE_NAMES.SUMMARY
];

export interface IRegTransition {
  transitionStart: (from: StateDeclaration, to: StateDeclaration, id: number) => void;
  transitionEnd: (from: StateDeclaration, to: StateDeclaration, id: number) => void;
  transitionFail: (from: StateDeclaration, to: StateDeclaration, id: number) => void;
}

export enum routeType {
  privateFunction = 'privateFunction',
  manageBooking = 'manageBooking',
  bookingPayment = 'bookingPayment'
}

export interface IInitialRoute {
  name: ROUTE_NAMES;
  type?: routeType;
}

export enum bookingAction {
  edit = 'edit',
  confirm = 'confirm',
  cancel = 'cancel',
  manageBooking = 'manage-booking',
  payment = 'payment',
  payments = 'payments',
  booking = 'booking'
}
