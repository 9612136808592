import {IAppSettings, IWidgetModel, themeTypes, wrapperStyleType} from 'app/models';
import {ROUTE_NAMES, STEP_ROUTES} from 'app/services/route/route.types';
import { loadStatus, loaderType } from 'app/types/common.types';
import {AppLoadService} from "app/services/appLoad.service";
import {ThemeColorsService} from "app/services/theme/themeColors.service";
import {ThemeTypographyService} from "app/services/theme/themeTypography.service";
import {AccountService} from "app/services/account/account.service";
import {BookingService} from "app/services/booking/booking.service";

export interface IRouteTransitions {
  id: number;
  from: ROUTE_NAMES;
  to: ROUTE_NAMES;
}

export interface INavigation {
  stepRoutes: ROUTE_NAMES[];
  currentStepIndex: number;
  currentRouteName: string;
  transitionIdIncrementer: number;
  transitions: IRouteTransitions[];
  loadingPaymentOnNext: boolean;
}

export interface ISetup {
  appLoadStatus: loadStatus;
  appLoadMessage: string;
  appErrorMessage: string;
  appLoaderType: loaderType;
  libPhoneNumberLoaded: boolean;
  libPhoneNumberLoading: boolean;
  // appSettings: IAppSettings;
  // accountDetails: IAccountDetails;
  // wrapperStyle: wrapperStyleType;
  // theme: IWidgetTheme;
}

export interface IRootState {
  widget: RootState.WidgetState;
  navigation: INavigation;
  setup: ISetup;
  router?: any; // maybe not needed
}

export namespace RootState {
  export type WidgetState = IWidgetModel;
}


export const getInitialWidgetState = (): RootState.WidgetState => {
  const appSettings: IAppSettings = AppLoadService.getAppSettings();
  return {
    editBookingDisabled: false,
    manageBookingErrorMessage: null,
    wrapperStyle: wrapperStyleType.standard,
    theme: {
      palette: ThemeColorsService.getThemeDefaults(appSettings.theme as themeTypes, !!appSettings.bgColor),
      type: themeTypes.light,
      defaultColors: true,
      typography: ThemeTypographyService.getThemeDefaults()
    },
    appSettings,
    accountDetails: AccountService.getEmptyAccountDetails(),
    venues: null,
    activeVenue: null,
    booking: BookingService.getBookingReset(),
    savedBooking: null,
    schedule: null,
    allVenueSchedule: null,
    allVenueTimes: [],
    selectedTimeForOtherVenues: null,
    scheduleLoadStatus: loadStatus.idle,
    allScheduleLoadStatus: loadStatus.idle,
    activeService: null,
    activeSection: null,
    manuallyChosenSectionId: null,
    activeChildMenuOption: null,
    cachedMenuOptionDetails: [],
    isTimeViewShown: false,
    timeError: null,
    filteredSections: [],
    filteredTimes: null,
    isCustomerDetailsValid: false,
    bookingError: null,
    bookingCancelled: {
      isCancelled: false,
      showBookAgainBtn: false
    },
    stripe: null,
    sessionRemainingTime: null, // '8:20 minutes' // used value for testing UI
    triedNext: null, // attempted pressing the 'Next' button in footer nav when disabled
    blockNav: null, // blocks the next nav
    hasPromoCodes: false,
    allVenueApiCall: false
  };
}


export const getInitialNavigationState = (): INavigation => ({
  // determines the order of routing when using prev/next
  stepRoutes: STEP_ROUTES,
  currentStepIndex: 0,
  currentRouteName: ROUTE_NAMES.SETUP,
  transitionIdIncrementer: 0,
  transitions: [],
  loadingPaymentOnNext: false
});

export const getInitialSetupState = (): ISetup => ({
  appLoadStatus: loadStatus.loading,
  appLoadMessage: 'Loading',
  appErrorMessage: null,
  appLoaderType: loaderType.hideContent,
  libPhoneNumberLoaded: false,
  libPhoneNumberLoading: false,
  // appSettings: AppLoadService.getAppSettings(),
  // accountDetails: AccountService.getEmptyAccountDetails(),
  // wrapperStyle: wrapperStyleType.standard,
  // theme: {
  //   palette: ThemeColorsService.getThemeDefaults(themeType, !!params.bg),
  //   type: themeTypes.light,
  //   defaultColors: true,
  //   typography: ThemeTypographyService.getThemeDefaults()
  // }
});
