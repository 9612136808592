import moment, {Moment} from "moment-timezone";
import { ISelectableTime } from "app/components/TimePicker/types";
import { SECTION_ANY_ID } from "app/components/SectionSelector/types";
import { IBooking } from "../booking/booking.types";
import {ISectionOrder} from "shared-types/index";
import {cloneDeep} from "lodash";

const NS = 'DateUtilsService';

export class DateUtilsService {

  /**
   * gets the current 24 hour time as a string at venue
   */
  static getCorrectTime(timeZone: string): string {
    const browserTime: string = moment().format();
    const updatedTime = moment.tz(browserTime, timeZone).format('YYYY-MM-DD H:mm:ss');
    return updatedTime;
  }

  static getCurrentTimeByTimeZone(timeZoneId: string, returnAsMoment = false): Date | Moment {
    const mom: Moment = moment.tz(moment(), timeZoneId);

    if (returnAsMoment) return mom;

    return mom.toDate();
  }

  static getJsDate = function (date: string): Date {
    const a: string[] = date.split(/[^0-9]/);
    const year: number = parseInt(a[0], 10);
    const month: number = parseInt(a[1], 10);
    const dayDate: number = parseInt(a[2], 10);
    const hours: number = parseInt(a[3], 10);
    const mins: number = parseInt(a[4], 10);
    const secs: number = parseInt(a[5], 10);

    return new Date(year, month - 1, dayDate, hours, mins, secs);
  }

  /**
   * Takes a moment object and timezone id and converts the moment to be relative to the venue's timezone.
   * If you were to call `.toDate()` after this you should get your local time with the offset added to it.
   * For example:
   *  - a booking in Perth at 'Thu Sep 03 2020 12:23:11'
   *  - would look like this for a Sydney user 'Thu Sep 03 2020 14:23:11 GMT+1000 (Australian Eastern Standard Time)'
   *  which has a 2 hour offset added to it
   * @param dateTime: eg a maxDate or a booking time
   * @param timeZone: eg 'Australia/Perth'
   */
  static convertMomentToVenueDateTime(dateTime: Moment, timeZone: string): void {
    dateTime.tz(timeZone);

    const userTZOffset = moment().utcOffset();
    const venueTZOffset = dateTime.utcOffset();
    const offsetInMins = userTZOffset - venueTZOffset;

    dateTime.add(offsetInMins, "minutes");
  }

  /**
   * Adds the UTC offset to a Date object to get the time as local
   */
  static getDateWithOffset(date?: Date, returnAsMoment = false): Date | Moment {
    const mom = date ? moment(date) : moment();
    mom.add(mom.utcOffset(), "minutes");

    if (returnAsMoment) return mom;
    return mom.toDate();
  }

  /**
   * Same as getFilteredTimes, but clones the times beforehand so originals are not modified.
   */
  static getFilteredTimesNonMutated(
    times: ISelectableTime[], omitExpired = true,
    sectionToFilterBy?: ISectionOrder, //savedBooking?: IBooking,
    utcTimeForSelectedState?: string
  ): ISelectableTime[] {
    return this.getFilteredTimesAndMutateOriginal(cloneDeep(times), omitExpired, sectionToFilterBy, utcTimeForSelectedState);
  }

  /**
   * CAREFUL! This function modifies the actual times within it, so if you are looping through sections of a service
   * using sectionToFilterBy, you may be accidentally mutating the times to isDisabled or isBlocked for the targeted
   * service. Make sure you cloneDeep the targeted service beforehand so the original service is not affected or
   * use getFilteredTimesNonMutated instead (above), but this may be less efficient when inside a sections loop.
   */
  static getFilteredTimesAndMutateOriginal(
    times: ISelectableTime[], omitExpired = true,
    sectionToFilterBy?: ISectionOrder, //savedBooking?: IBooking,
    utcTimeForSelectedState?: string
  ): ISelectableTime[] {

    let filteredTimes: ISelectableTime[] = times ? times.slice() : [];
    if (omitExpired) {
      filteredTimes = filteredTimes.filter(t => {

        const sectionId = sectionToFilterBy ? sectionToFilterBy.id : null;

        /**
         * Difference between isDisabled and isBlocked is that the former is for when a booking is unavailable due
         * to another booking taking it's place. But isBlocked is more like when an event is overlapping the time,
         * so it is never going to be available. If sectionState is false, then isBlocked should be true, as it
         * indicates the time is never available in that section.
         */
        t.isDisabled = !DateUtilsService.isTimeAvailable(t, sectionId);

        if (!sectionId || sectionId === SECTION_ANY_ID) { // if all the sections are blocked and ANY or nothing (null) has been chosen
          t.isBlocked = t.sections.every(s => s.isSectionBlocked);
        } else { // if the selected section is blocked
          t.isBlocked = t.sections.some(s => sectionId === s.id && s.isSectionBlocked);
        }

        t.isSavedTime = false; // savedBooking ? DateUtilsService.isSavedTime(t, savedBooking) : false;

        return !t.expired
      });
    }

    filteredTimes.forEach(t => {
      t.isSelected = !!(utcTimeForSelectedState && t.time === utcTimeForSelectedState);
    });

    return filteredTimes;
  }

  private static isTimeAvailable(time: ISelectableTime, sectionId: string): boolean {

    if (time.expired) {
        return false;
    }

    const sections = time.sections;
    // variable create to help debugging
    let isAvailable = false;

    if (sectionId && sectionId !== SECTION_ANY_ID) {
        isAvailable = !!sections.find(s => sectionId === s.id && s.sectionState);
        return isAvailable;
    }

    isAvailable = !!sections.find(s => s.sectionState);
    return isAvailable;
  }

  private static isSavedTime(time: ISelectableTime, booking: IBooking): boolean {

    if (!booking) {
        return false;
    }

    return booking.utcTime === time.time;
  }

  static checkForLastDate(date: Moment): boolean {
    const tomorrow = moment().add(1,'days');
    return tomorrow.isSameOrAfter(date, 'day');
  }

}
