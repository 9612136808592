import {ThemeOptions} from "@material-ui/core/styles/createMuiTheme";
import {IAccountDetails} from "app/services/account/account.types";
import {ISimpleVenues} from "app/components/Venues/interfaces";
import {loadStatus} from "app/types/common.types";
import {
  IBooking,
  ICustomer,
  ISavedBooking
} from "app/services/booking/booking.types";
import {
  IWidgetSettings,
  ISchedule,
  IEwayInfo,
  IStripeInfo,
  IPrepareEwayData,
  IPaymentType
} from "app/services/client/client.types";
import {IWidgetPalette} from "app/services/theme/theme.types";
import {IBookingError, IBookingErrorMinimal} from "app/services/error/error.types";
import {bookingAction, ROUTE_NAMES} from "app/services/route/route.types";
import {IPaymentDetailsGenericData} from "app/components/PaymentDetailsGeneric/types";
import {TypographyOptions} from "@material-ui/core/styles/createTypography";
import {ISelectableTime} from "app/components/TimePicker/types";
import {IActiveChildMenuOption} from "shared-components/booking-options-section/ChildMenuOptionOverlay/types";
import {
  ISimpleBookingOption,
  IScheduleTime,
  ISectionOrder,
  IScheduleService,
  IBookingTag
} from "shared-types/index";

export interface IAppSettings {
  accountId?: string;
  venueId?: number;
  fromURL?: string;
  startURL?: string;
  theme?: string;
  font?: string;
  accent?: string;

  /**
   * Booking Id could come from the url or saving the booking
   * this reference is used throughout the app rather than the
   * one on the booking
   */
  bookingId?: string;

  tokenId?: string;
  date?: string;
  serviceids?: string;
  covers?: number;
  action?: bookingAction;
  source?: string;
  debug?: string;
  mode: modeType;
  canDeleteBooking: boolean;
  isStyleGuide: boolean;
  eventId?: string;
  privateFunction?: boolean;
  bgColor?: string;
  banner?: string;
  time?: string;
}

export interface IDayPickerModel {
  a: any;
}

export enum themeTypes {
  outlinedLight = 'outlined-light',
  outlinedDark = 'outlined-dark',
  light = 'light',
  dark = 'dark'
}

export enum modeType {
  normal = 'normal',
  preview = 'preview'
}

export enum bannerType {
  hidden = 'hidden' // @todo: save into diary and back end as a new query string parameter so that links will include it. severity: high
}

export enum wrapperStyleType {
  narrow = 'narrow',
  stacked = 'stacked',
  standard = 'standard',
  wide = 'wide'
}


export enum analyticsParams {
  bubble = 'bubble'
}

export interface IWidgetTypography extends TypographyOptions {
  fontFamily?: string;
}

export interface IWidgetTheme extends ThemeOptions {
  type: themeTypes;
  defaultColors: boolean;
  typography?: IWidgetTypography;
  palette?: IWidgetPalette;
}

export interface IAnalyticsKeys {
  facebookApi: string;
  googleApi: string;
}


export interface IVenueMinimal {
  active: boolean;
  name: string;
  phone: string;
  city: string;
  state: string;
  country: string;
  email: string;
  logoUrl?: string;
  widgetSettings: IWidgetSettings;
  paymentSettings: IEwayInfo | IStripeInfo;
  canCancelBookingBySms: boolean;
  canCustomersCancelBookings: boolean;
  canCustomersEditBookings: boolean;
  canEditBookingBySms: boolean;
  groupAvailabilityActive?: boolean;
  groupAvailabilitySubscriptionActive?: boolean;
}

// very similar to IOwnedVenue, but not identical
export interface IVenue extends IVenueMinimal {
  id: number;
  accountId?: string;
  currency: string; // USD, AUD, GBP, etc
  timeZoneId: string;
  clientSideEncryptKey: string;
  analytics: IAnalyticsKeys;
  preAuthReleasingWindow?: number;
  maxPeoplePerBooking?: number;
}

export interface IManageBookingError {
  title: string;
  message: string;
}

export enum blockNavType {
  becauseOfPayment = 1,
  becauseOfCoversPending = 2,
  becauseNoAvailableTimes = 3,
  becauseScheduleError = 4
}

export interface IWidgetModel {
  /**
   * `displayEditCantChangeMessage` will be `true` when saved booking has been paid for and can't be changed.
   * Called `DisplayEditCantCahgneMessage` in widget v1.
   */
  editBookingDisabled: boolean;

  isTimeViewShown: boolean;
  timeError: string;
  appSettings: IAppSettings;
  accountDetails: IAccountDetails;
  manageBookingErrorMessage: IManageBookingError;
  wrapperStyle: wrapperStyleType;
  venues: ISimpleVenues;
  activeVenue: IVenue | IVenueMinimal; // equivalent of store.venue in widget v1
  theme: IWidgetTheme;
  booking: IBooking;
  savedBooking: ISavedBooking;
  schedule: ISchedule;
  allVenueSchedule: ISchedule[];
  allVenueTimes:IScheduleTime[];
  selectedTimeForOtherVenues:IScheduleTime;
  scheduleLoadStatus: loadStatus;
  allScheduleLoadStatus: loadStatus;
  activeService: IScheduleService;
  activeSection: ISectionOrder;
  manuallyChosenSectionId: string;
  activeChildMenuOption: IActiveChildMenuOption;
  cachedMenuOptionDetails: ISimpleBookingOption[];
  filteredSections: ISectionOrder[];
  filteredTimes: ISelectableTime[];
  isCustomerDetailsValid: boolean;
  bookingError: IBookingErrorMinimal | IBookingError;
  bookingCancelled: IBookingCancelled;
  stripe: stripe.Stripe; // loaded from src/app/components/PaymentDetailsStripe/index.tsx
  sessionRemainingTime: string;
  triedNext: ROUTE_NAMES;
  paymentType?: IPaymentType; // used to display message on the summary page before saving booking
  blockNav?: blockNavType;
  standbyData?: IStandbyData;
  standbyCleared?: boolean;
  standbyExhausted?: boolean;
  hideUpsell?: boolean;
  hasPromoCodes: boolean;
  coversChanged?: boolean;
  haveAcceptedVerification?: boolean;
  isUpsellPresent?: boolean;
  allVenueApiCall?:boolean;
  hasMinimumPax?:boolean;
  phoneWithoutPrefix?: string;
  country?: string;
  maxPaxOverride?: number;
}

export interface IBookingCancelled {
  isCancelled: boolean;
  showBookAgainBtn: boolean;
}

export interface IEwayPaymentStep1Data {
  paymentDetails: IPaymentDetailsGenericData;
  prepareEwayPayment: IPrepareEwayData;
}

export interface IAppQueryStringParams {
  mode?: modeType;
  colors?: string; // interchangable with 'accent'
  accent?: string; // interchangable with 'colors'
  font?: string;
  theme?: string;
  accountid: string;
  venueid?: string;
  fromurl?: string;
  bookingid: string;
  token?: string;
  date?: string;
  serviceids?: string;
  covers?: string;
  source?: string;
  debug?: string;
  bg?: string;
  banner?: string;
  analytics?: analyticsParams;
  mobilescroll?: 'none';
  time?: string;
}

export interface IStandbyData {
  viewDate: string;
  covers: number;
  serviceId: string;
  serviceName: string; // "Dinner"
  sectionId: string;
  utcTime: string;
  viewTime: string;
  isFlexibleTime?: boolean;

  tags: IBookingTag[];
  customer: ICustomer;
}
