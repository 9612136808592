export enum loadStatus {
  idle = 'idle',
  loading = 'loading',
  loaded = 'loaded',
  failed = 'failed',
  failedTemp = 'failedTemp',
  success = 'success'
}

export enum loaderType {
  overlayContent = 'overlayContent',
  hideContent = 'hideContent'
}

export interface IAction {
  type: string;
  payload?: any;
}

export interface IActionGen<T> {
  type: string;
  payload?: T;
}
